
import { defineComponent, PropType } from '@nuxtjs/composition-api'
// @ts-expect-error
import { SwiperOptions } from 'swiper/types'
import { getProductSku, getSlug } from '../getters/productGetters'
import ProductCard from '~/components/organisms/ProductCard.vue'
import { ProductInterface } from '~/modules/GraphQL/types'
import { PRODUCTS_CAROUSEL_OPTIONS } from '~/resources/ProductsCarouselOptions'
import { createProductPath } from '~/modules/product-correct-url/helpers'
import { useSwiper } from '~/composables/useSwiper'
import { SwiperNavigations } from '~/components/swiper'
import LazyFor from '~/components/LazyFor.vue'

export default defineComponent({
  name: 'ProductsCarousel',
  components: { LazyFor, ProductCard, SwiperNavigations },
  props: {
    products: {
      type: Array as PropType<ProductInterface[]>,
      default: () => ([])
    },
    swiperOptions: {
      type: Object as PropType<SwiperOptions>,
      default: () => PRODUCTS_CAROUSEL_OPTIONS
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { setInstance, swiper: swiperInstance } = useSwiper()

    return {
      getProductSku,
      getSlug,
      createProductPath,
      setInstance,
      swiperInstance
    }
  }
})
