
export default {
  name: 'BaseHeading',
  props: {
    level: {
      type: Number,
      default: 2
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    highlightTitle: {
      type: String,
      default: ''
    }
  }
}
