// @ts-expect-error
import { SwiperOptions } from 'swiper/types'

export const PRODUCTS_CAROUSEL_OPTIONS: SwiperOptions = {
  spaceBetween: 10,
  grabCursor: false,
  slidesPerView: 'auto',
  freeMode: false,
  breakpoints: {
    1440: {
      slidesPerView: 5,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0
    }
  }
}
