<template>
  <div class="grid-wrapper">
    <div class="grid container">
      <div class="grid-top">
        <heading
          v-if="mainTitle"
          class="grid-title heading--left"
          :title="mainTitle"
          :level="2"
        />
        <slot name="link" />
      </div>
      <div class="grid-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '~/components/atoms/Heading.vue'

export default {
  name: 'GridOfCards',
  components: { Heading },
  props: {
    mainTitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  &-title {
    --heading-title-color: #000;
    --heading-title-font-weight: 500;
    --heading-title-font-size: 26px;
    --heading-title-font-line-height: 32px;
    --heading-padding: 0;

    @include for-mobile {
      --heading-title-font-weight: 500;
      --heading-title-font-size: 20px;
      --heading-title-font-line-height: 28px;
    }
  }

  &-top {
    display: flex;
    gap: 8px 16px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;

    @include for-desktop {
      margin-bottom: 30px;
      gap: 16px 24px;
    }
  }

  &-content {
    box-sizing: border-box;
  }
}

</style>
